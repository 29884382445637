import { useState } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { HighlightReelPlayer } from '../components/HighlightReelPlayer/HighlightReelPlayer';

export interface HighlightReelPlayerContainerProps {
  promptText: string;
}

export function HighlightReelPlayerContainer({ promptText }: HighlightReelPlayerContainerProps) {
  const isMobile = useIsMobileViewport();
  const [controlsVisible, setControlsVisible] = useState(true);

  return (
    <HighlightReelPlayer
      controlsVisible={controlsVisible}
      onVideoClick={() => setControlsVisible((controlsVisible) => !controlsVisible)}
      mobile={isMobile}
      promptText={promptText}
    />
  );
}

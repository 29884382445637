import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: visible;
  width: 100%;

  body[data-mobile='true'] & {
    justify-content: flex-start;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

export const MediaWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--surface-always-dark);
  overflow: hidden;
  position: relative;

  body[data-mobile='true'] & {
    max-width: 100vw;
    border-radius: 0;
    border: none;
  }

  body[data-fullscreen='true'] & {
    max-height: 100vh;
  }
`;

const trackContainerHeight = '22.5px';

export const AbsoluteMediaContainer = styled.div<{
  mobile?: boolean;
  showing: boolean;
}>`
  width: calc(100%);
  position: absolute;
  object-fit: cover;
  z-index: 1;
  bottom: 0;
  left: 0;
  transition: all 500ms ease;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(20, 31, 31, 0) 100%);

  padding: var(--spacing-md);
  transform: ${({ showing }) =>
    showing ? 'translateY(calc(0rem)' : `translateY(calc(100% - ${trackContainerHeight}))`};
`;

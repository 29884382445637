import { useCallback, useState } from 'react';

import { useIsFullscreen } from '@/hooks/useIsFullscreen';
import { PlayerScrubber } from '@/modules/media-player/PlayerScrubber';

import { StoryVideoPlayer } from '../StoryVideoPlayer/StoryVideoPlayer';

import { AbsoluteMediaContainer, Container, Content, MediaWrapper } from './HighlightReelPlayer.styles';

interface HighlightReelPlayerProps {
  controlsVisible: boolean;
  mobile?: boolean;
  promptText: string;
  onVideoClick: () => void;
}

export function HighlightReelPlayer({ controlsVisible, mobile, promptText, onVideoClick }: HighlightReelPlayerProps) {
  const isFullscreen = useIsFullscreen();
  const [hovering, setHovering] = useState(false);

  const handleHover = useCallback(
    (hovering: boolean) => {
      if (mobile) return;

      setHovering(hovering);
    },
    [mobile],
  );

  const showing = mobile ? controlsVisible : hovering;

  return (
    <Container>
      <Content>
        <MediaWrapper
          onClick={mobile ? onVideoClick : undefined}
          onMouseOver={() => handleHover(true)}
          onMouseOut={() => handleHover(false)}
        >
          <StoryVideoPlayer fitMode={isFullscreen ? 'contain' : 'cover'} />
          <AbsoluteMediaContainer
            onClick={(event) => event.stopPropagation()}
            showing={showing || isFullscreen}
            onMouseOver={!mobile ? undefined : () => handleHover(true)}
            onMouseOut={!mobile ? undefined : () => handleHover(false)}
          >
            <PlayerScrubber promptText={promptText} />
          </AbsoluteMediaContainer>
        </MediaWrapper>
      </Content>
    </Container>
  );
}

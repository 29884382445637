import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const Page = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--surface-always-dark);
  display: flex;
  justify-content: center;
`;

export const PageWrapper = styled.div`
  min-width: 34.375rem;
  border: 1px solid var(--inverse-border-hairline);
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  body[data-mobile='true'] & {
    min-width: unset;
    border: none;
    width: 100%;
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  height: 3.5rem;

  body[data-mobile='true'] & {
    padding: var(--spacing-xs);
  }
`;

export const HeaderButton = styled.div`
  position: absolute;
  right: var(--spacing-md);

  body[data-mobile='true'] & {
    right: var(--spacing-xs);
  }
`;

export const Wordmark = styled(RMWordmark)`
  padding-left: 1.875rem;
  body[data-mobile='true'] & {
    height: 1rem;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  body[data-mobile='true'] & {
    padding-bottom: var(--spacing-xl);
  }
`;

export const ContentWrapper = styled.div``;

export const VideoWrapper = styled.div`
  min-height: calc(100dvh - 18rem);
  max-height: calc(100dvh - 18rem);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0 auto;

  body[data-mobile='true'] & {
    min-height: 100vw;
  }
`;

export const Footer = styled.div`
  display: flex;
  gap: var(--spacing-xl);
  padding: var(--spacing-lg);

  body[data-mobile='true'] & {
    gap: var(--spacing-md);
    padding: var(--spacing-md);
    width: 100vw;
  }
`;

export const StoryTitle = styled(RMText)`
  display: block;
  padding: var(--spacing-lg) var(--spacing-lg) 0 var(--spacing-lg);
  body[data-mobile='true'] & {
    padding: var(--spacing-md) var(--spacing-md) 0 var(--spacing-md);
  }
`;

export const ReactionButton = styled(RMButton)`
  padding: 0;
  min-width: 3rem;
`;

export const SignInBanner = styled.div`
  padding: var(--spacing-sm) var(--spacing-md);
  background: var(--banner-info);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);
  cursor: pointer;
`;
